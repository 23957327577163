import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
import { LDrawLoader } from "three/addons/loaders/LDrawLoader.js";

const ldrawPath = "./";
const modelFileName = "7140-1-X-wingFighter.mpd_Packed.mpd";
var model = null;
var BUILDING_STEPS = 0;
var last = 0; // timestamp of the last render() call

// Canvas
// get the canvas using id test
const canvas = document.getElementById("homepage-3D");

// Scene
const scene = new THREE.Scene();

function onProgress(xhr) {
  if (xhr.lengthComputable) {
    console.log(Math.round((xhr.loaded / xhr.total) * 100, 2) + "% downloaded");
  }
}

function onError(error) {
  const message = "Error loading model";
  console.log(message);
  console.error(error);
}

function updateObjectsVisibility(model, buildingSteps) {
  model.traverse(c => {
    if (c.isLineSegments) {
      if (c.isConditionalLine) {
        c.visible = true;
      } else {
        c.visible = true;
      }
    } else if (c.isGroup) {
      // Hide objects with building step > gui setting
      c.visible = c.userData.buildingStep <= buildingSteps;
    }
  });
}

function reloadObject(resetCamera) {
  // only smooth when not rendering with flat colors to improve processing time
  const lDrawLoader = new LDrawLoader();
  lDrawLoader.smoothNormals = true;
  lDrawLoader.setPath(ldrawPath).load(
    modelFileName,
    function (group2) {
      model = group2;

      // demonstrate how to use convert to flat colors to better mimic the lego instructions look

      function convertMaterial(material) {
        const newMaterial = new THREE.MeshBasicMaterial();
        newMaterial.color.copy(material.color);
        newMaterial.polygonOffset = material.polygonOffset;
        newMaterial.polygonOffsetUnits = material.polygonOffsetUnits;
        newMaterial.polygonOffsetFactor = material.polygonOffsetFactor;
        newMaterial.opacity = material.opacity;
        newMaterial.transparent = material.transparent;
        newMaterial.depthWrite = material.depthWrite;
        newMaterial.toneMapping = false;

        return newMaterial;
      }

      model.traverse(c => {
        if (c.isMesh) {
          if (Array.isArray(c.material)) {
            c.material = c.material.map(convertMaterial);
          } else {
            c.material = convertMaterial(c.material);
          }
        }
      });

      // Convert from LDraw coordinates: rotate 180 degrees around OX
      model.rotation.x = Math.PI;

      updateObjectsVisibility(model, 0);

      scene.add(model);

      // Adjust camera and light

      const bbox = new THREE.Box3().setFromObject(model);
      const size = bbox.getSize(new THREE.Vector3());
      const radius = Math.max(size.x, Math.max(size.y, size.z)) * 0.5;

      if (resetCamera) {
        controls.target0.copy(bbox.getCenter(new THREE.Vector3()));
        controls.position0
          .set(-2.3, 1, 2)
          .multiplyScalar(radius)
          .add(controls.target0);
        controls.reset();
      }
    },
    onProgress,
    onError
  );
}

/**
 * Objects
 */

// load materials and then the model

reloadObject(true);

/**
 * Sizes
 */

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */

// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  window.innerWidth / window.innerHeight,
  1,
  10000
);
camera.position.set(150, 200, 250);
scene.add(camera);

/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.toneMapping = THREE.ACESFilmicToneMapping;

const pmremGenerator = new THREE.PMREMGenerator(renderer);

scene.background = new THREE.Color(0xdeebed);
scene.environment = pmremGenerator.fromScene(
  new RoomEnvironment(renderer)
).texture;

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Animate
 */

const tick = now => {
  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  if (!last || now - last >= 0.1 * 1000) {
    last = now;
    if (model) {
      BUILDING_STEPS = BUILDING_STEPS <= 100 ? BUILDING_STEPS + 1 : 0;
      updateObjectsVisibility(model, BUILDING_STEPS);
    }
  }

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
